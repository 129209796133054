import {
	Box,
	HStack,
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
	VStack,
} from "@chakra-ui/react"
import { TextWithFurigana } from "@components/TextWithFurigana"
import { CheckCircleIcon } from "@icons/CheckCircleIcon"
import { EyeIcon } from "@icons/EyeIcon"
import { EyeWithLineIcon } from "@icons/EyeWithLineIcon"
import { colors } from "@styles/colors"
import { FC, useState } from "react"

export type InputCondition = {
	isValid: boolean
	conditionLabel: {
		text: string
		furigana: string
	}
}

type Props = {
	inputConditions?: InputCondition[]
} & InputProps

export const PasswordInput: FC<Props> = ({
	inputConditions,
	...restInputProps
}) => {
	const [passwordIsVisible, setPasswordIsVisible] = useState(false)

	return (
		<VStack spacing={1} w="100%">
			<InputGroup>
				<Input
					{...restInputProps}
					type={passwordIsVisible ? "text" : "password"}
				/>
				<InputRightElement>
					<Box
						style={{ padding: 4, marginRight: 6 }}
						onClick={() => setPasswordIsVisible(!passwordIsVisible)}
					>
						{passwordIsVisible ? (
							<EyeIcon color={colors.text.black[400]} size={24} />
						) : (
							<EyeWithLineIcon color={colors.text.black[400]} size={24} />
						)}
					</Box>
				</InputRightElement>
			</InputGroup>
			{inputConditions && (
				<VStack w="100%" spacing={1} mt={1.5} justifyContent="flex-start">
					{inputConditions.map((validation) => (
						<HStack
							alignItems="center"
							alignSelf={"flex-start"}
							spacing={2}
							key={validation.conditionLabel.text}
						>
							<CheckCircleIcon
								color={
									validation.isValid
										? colors.ui.primary[500]
										: colors.text.black[300]
								}
								size={16}
								style={{ marginTop: 6 }}
							/>
							<TextWithFurigana
								furigana={validation.conditionLabel.furigana}
								align="left"
							>
								{validation.conditionLabel.text}
							</TextWithFurigana>
						</HStack>
					))}
				</VStack>
			)}
		</VStack>
	)
}
