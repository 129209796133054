import { Button, ButtonProps } from "@chakra-ui/react"
import React, { FC } from "react"

type Props = {
	loadingText: JSX.Element | string
	children: React.ReactNode
} & Omit<ButtonProps, "loadingText">

export const ButtonWithCustomLoadingComponent: FC<Props> = ({
	loadingText,
	children,
	...restButtonProps
}) => {
	return (
		<Button {...restButtonProps}>
			{restButtonProps.isLoading ? loadingText : children}
		</Button>
	)
}
