import {
	Link as ChakraLink,
	LinkProps as ChakraLinkProps,
} from "@chakra-ui/react"
import { colors } from "@styles/colors"
import NextLink from "next/link"
import React, { ComponentProps, FC, useMemo } from "react"

export type NextLinkUrl = ComponentProps<typeof NextLink>["href"]

const DEFAULT_STYLE = {
	style: { textDecoration: "none" },
	display: "inline-flex",
	variant: "",
}

type Props = {
	href?: NextLinkUrl
	children: React.ReactNode
	isDisabled?: boolean
	nextLinkProps?: Omit<ComponentProps<typeof NextLink>, "href">
	colored?: boolean
	shouldOpenWithNewTab?: boolean
} & Omit<ChakraLinkProps, "href" | "children">

export const CCLink: FC<Props> = ({
	href,
	children,
	isDisabled,
	nextLinkProps,
	colored,
	shouldOpenWithNewTab,
	...chakraLinkProps
}) => {
	const newTabProps = useMemo(
		() =>
			shouldOpenWithNewTab
				? {
						target: "_blank",
						rel: "noopener noreferrer",
				  }
				: {},
		[shouldOpenWithNewTab],
	)

	if (!href)
		return (
			<ChakraLink {...DEFAULT_STYLE} {...newTabProps} {...chakraLinkProps}>
				{children}
			</ChakraLink>
		)

	return isDisabled ? (
		<ChakraLink
			{...DEFAULT_STYLE}
			opacity={0.7}
			_hover={{ cursor: "not-allowed" }}
			{...newTabProps}
			{...chakraLinkProps}
		>
			{children}
		</ChakraLink>
	) : (
		<NextLink passHref href={href} {...nextLinkProps}>
			<ChakraLink
				{...DEFAULT_STYLE}
				color={colored ? colors.ui.primary[500] : undefined}
				{...newTabProps}
				{...chakraLinkProps}
			>
				{children}
			</ChakraLink>
		</NextLink>
	)
}
