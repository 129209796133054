import { useAuth } from "@hooks/useAuth"
import { useRedirectIfNeeded } from "@hooks/useRedirectIfNeeded"
import { isUserAuthType } from "@stores/userInfo"
import { useEffect } from "react"

export const useRedirectIfLoggedIn = () => {
	const { redirect } = useRedirectIfNeeded()
	const { userInfo } = useAuth()

	useEffect(() => {
		2 <= window.history.length &&
			isUserAuthType(userInfo) &&
			setTimeout(() => redirect(userInfo), 300)
	}, [])
}
