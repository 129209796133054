import { Button } from "@chakra-ui/react"
import { CCLink, NextLinkUrl } from "@components/CCLink"
import NextLink from "next/link"
import React, { ComponentProps, FC } from "react"

type Props = {
	children: React.ReactNode
	href: NextLinkUrl
} & ComponentProps<typeof Button>

export const LinkButton: FC<Props> = ({
	href,
	children,
	...restButtonProps
}) => {
	return (
		<NextLink href={href} passHref>
			<Button {...restButtonProps} as={CCLink}>
				{children}
			</Button>
		</NextLink>
	)
}
